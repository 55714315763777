<template>
  <v-app :style="colorVapp">
    <onebox_toolbar
    :aspectSearch="aspectData"
      :quicksearch="true"
      :parentfolder="parentfolder"
      :permissionfolder="permission_currentfolder"
    ></onebox_toolbar>
    <v-content ref="myfile">
      <v-card class="elevation-0">
        <v-divider></v-divider>
        <v-overlay :value="processloader" absolute color="black" dark>
          <v-progress-circular indeterminate size="64" color="white"></v-progress-circular>
          <span :style="colorProgress">&nbsp; loading</span>
        </v-overlay>
        <v-list nav :color="color.BG">
          <v-list-item class="text-left">
            <v-list-item-avatar class="text-center">
              <v-avatar :color="color.theme" size="35">
                <v-icon dark size="20">mdi-library</v-icon>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <span
                  :style="headerPage" 
                >&nbsp;{{$t('toolbar.library')}}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-card class="elevation-0" :color="color.BG" id="thiscontainerlibrary">
          <v-card-text class="pa-0">
              <v-container   fluid>
                  <v-layout row wrap fill-height  justify-center>
                    <!-- <v-flex lg12 class="ma-2 ml-4 mr-4" v-if="loaddataprogress === true">
                  <div class="text-center">
                    <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
                  </div>
                </v-flex> -->
                 <v-flex lg12 class="ma-4" >
                  <!-- <v-layout row wrap fill-height  class="pl-2 pr-2"  >
               </v-layout> -->
               <!-- <br /> -->
                    <!-- <v-layout v-if="resolutionScreen >= 500 ">
                    <v-flex xs4 lg4>
                    </v-flex>
                  
                  </v-layout> -->
                  <!-- <v-layout row wrap class="pl-3 pr-3" v-else>
                    
                    -->
                  <!-- </v-layout> -->
                  <!-- หน้าจอxs -->

                    <!-- <br /> -->
                    
                  <div v-if="resolutionScreen <= 500">
                    <div v-if="loaddataprogresstable === true "><v-flex lg12 class="ma-2 ml-4 mr-4" >
                  <div class="text-center">
                    <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
                  </div>
                </v-flex></div>
                    <v-list two-line class="mb-6 pb-6 elevation-0" v-if="rootfile.length>0" >
                      <v-list-item
                        v-for="(item,index) in rootfile"
                            :key="index"
                            @contextmenu="clickRight($event,item)"
                            
                            style="cursor: pointer;"
                      >
                        <v-list-item-action>
                           <v-icon large :color="item.file_icon[1]">{{ item.file_icon[0] }}</v-icon>
                        </v-list-item-action>

                        <v-list-item-content
                        >
                           <v-list-item-title v-text="item.file_name"></v-list-item-title>
                              <v-list-item-subtitle v-text="formatdatetime(item.date_effect)"></v-list-item-subtitle>
                        </v-list-item-content>

                        <!-- <v-list-item-action>
                          <v-btn class="elevation-0" fab small >
                            <v-icon>settings</v-icon>
                          </v-btn>
                        </v-list-item-action> -->
                      </v-list-item>
                    
                      <v-divider inset></v-divider>
                    </v-list>
                    <v-list  class=" elevation-0" v-else>
                     <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title class="text-center" v-text="$t('tablefile.empty')"></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list> 
                   </div>  
                  <div v-else>
                  <!-- <div v-if="loaddataprogresstable === true "><v-flex lg12 class="ma-2 ml-4 mr-4" >
                  <div class="text-center">
                    <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
                  </div>
                </v-flex></div> -->
                  <!-- <div v-else> -->
                    <v-data-table 
                     :no-data-text="$t('tablefile.empty')"                  
                      :headers="headers"
                       class="elevation-0 drag-selector pa-1"
                      :items="rootfile"
                      :hide-default-footer="true" 
                      :items-per-page="rootfile.length"
                    >
                      <!-- <template v-slot:top>
                          <v-switch v-model="singleSelect" label="Single select" class="pa-3"></v-switch>
                      </template>-->
                      <template v-slot:[`header.file_type`]="{ header }">
                        <span
                          :style="headerTable"
                        >{{ header.text }}</span>
                      </template>
                      <template v-slot:[`header.file_name`]="{ header }">
                        <span 
                          class="pointer"
                          
                          :style="headerTable"
                        >{{ $t(header.text) }}</span>
                      </template>
                      <template v-slot:[`header.doc_id`]="{ header }">
                        <span
                          class="pointer"
                         
                           :style="headerTable"
                        >{{ $t(header.text) }}</span>
                      </template>
                      <template v-slot:[`header.date_effect`]="{ header }">
                        <span
                          class="pointer"
                          
                           :style="headerTable"
                        >{{ $t(header.text) }}</span>
                      </template>
                      <template v-slot:[`header.tag_version`]="{ header }">
                        <span
                          class="pointer"
                       
                           :style="headerTable"
                        >{{ $t(header.text) }}</span>
                      </template>
                      <!-- <template v-slot:header.file_status="{ header }">
                        <span 
                           :style="headerTable"
                        >{{ $t(header.text) }}</span>
                      </template> -->
                      <template v-slot:item="props">
                        <tr
                          style="cursor: pointer"
                          @contextmenu="clickRight($event,props.item)"
                        >
                          <td width="7%" class="text-center">
                              <v-icon
                                large
                                :color="props.item.file_icon[1]"
                              >{{ props.item.file_icon[0] }}</v-icon>                       
                          </td>
                          <td width="33%">{{props.item.file_name}}</td>
                          <!-- <td width="15%"  v-if="$t('default') === 'th'">{{ props.item.file_owner_th}}</td> -->
                           <td width="15%" >{{ props.item.doc_id}}</td>
                          <td width="18%">{{ formatdatetime(props.item.date_effect)}}</td>
                          <td width="15%">{{ props.item.tax_version}}</td>
                          <!-- <td width="10%" class="text-center"></td>
                          <td></td> -->
                        </tr>
                      </template>
                    </v-data-table>
                  </div>
                <!-- </div> -->
                  <!-- <infinite-loading @infinite="loadfile"></infinite-loading> -->
                  <!-- <br />
                  <v-layout row wrap justify-center v-if="rootfile.length > 0">
                     <v-flex xs11 lg11 >
                      <v-pagination
                       :total-visible="10"
                        v-model="page"
                        :length="pageCount || 0"
                        :color="color.theme"
                        @input="clickpagination()"
                      ></v-pagination>
                    </v-flex> 
                  </v-layout>                -->
                    </v-flex>
                  </v-layout>
                <scroll-loader class="pa-0" :loader-method="loadfileandfolder" :loader-disable="disablescrolling">
              </scroll-loader>  
               <div class="text-center" v-if="loaddataprogress === true">
                  <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
                </div>         
              </v-container>  
              <libraryrightclick
              :parentfolder="parentfolder"
               :show="showMenu"
              :AreaX="x"
              :AreaY="y"
              :file="currentfile"
              @downloadfile="downloadfile(currentfile)"
            ></libraryrightclick> 
             <!-- <dialogsnackbermultidowload
              :show="opensnackbarmultidownload"
              @closedialog="opensnackbarmultidownload = false"
              :percent='percent'
              :date='datetime'
            ></dialogsnackbermultidowload>  -->
            <dialogsnackbardowload
             :show="opensnackbar"
             @closedialog="opensnackbar=false"
             :filedata="currentfile"
             :percen="newpercen"
             :name="namefile"
             :list="new_list"
    ></dialogsnackbardowload>           
              </v-card-text>
              </v-card>
     
      </v-card>
    </v-content>
  </v-app>  
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapState, mapGetters } from "vuex";
import InfiniteLoading from "vue-infinite-loading";
import "vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css";
import VueFullScreenFileDrop from "vue-full-screen-file-drop";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import onebox_toolbar from "../components/layout/layout-toolbar-new";
const libraryrightclick = () =>
  import("../components/contextmenu/libraryrightclick");
const dialogsnackbardowload =() => import("../components/optional/dialog-snackbardownload");
const dialogsnackbermultidowload = () => import("../components/optional/dialog-snackbarmultidowmload");
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000
});

export default {
  components: { 
    dialogsnackbardowload,
    onebox_toolbar,
    libraryrightclick,
    dialogsnackbermultidowload
  },
  data: function () {
    return { 
      new_list:[],
      namefile:'',
      newpercen:[],
      filemultipledownload:[],
      opensnackbarmultidownload:false,
      opensnackbar:false,
      percent:0,
      percentCompleted: 0,
      datetime: "",
        timeout: 60000,
        timeoutdowload: 60000000,
        snackbardowloadfile: false,
        listdatadowload: [],
        newlist: [],
      currentfile: {
        file_icon: ["", ""],
        file_id: "",
        file_name: "",
        file_size: "",
        file_status: "",
        file_type: ""
      },
      showMenu:false,
        x:0,
        y:0,
        permission_currentfolder: {
        download: "False",
        edit: "False",
        view_only: "True",
        upload:"False",
        delete:"False",
      },
      aspectData: [{ aspect: "False", aspect_key: [] }],
      disablescrolling :true,
      page: 1,
      offset:0,
      count:0,
      size: 20,
      listsize: [10, 20, 50, 100],
      parentfolder: "",
      loaddataprogress:true,
      loaddataprogresstable:false,
      rootfile:[],
      headers: [
        {
          text: "#",
          align: "left",
          value: "file_type",
          width: "7%",
          sortable: false
        },
        {
          text: "tablefile.filename",
          align: "left",
          value: "file_name",
          width: "33%",
          sortable: false
        },
        {
          text: "tablefile.doc_id",
          align: "left",
          value: "doc_id",
          width: "15%",
          sortable: false
        },
        {
          text: "tablefile.date_effect",
          value: "date_effect",
          width: "18%",
          align: "left",
          sortable: false
        },
        {
          text: "tablefile.tag_version",
          value: "tag_version",
          width: "15%",
          align: "left",
          sortable: false
        },
        // {
        //   text: "tablefile.option",
        //   value: "file_status",
        //   width: "12%",
        //   align: "left",
        //   sortable: false
        // }
      ], 
  }
  },
  computed:{
        ...mapState([
      "username",
      "authorize",
      "account_active",
      "service",
      "color",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
    ]),
    headerPage() {
      // console.log(this.color.theme);

      return (
        "color:" + this.color.theme + ";" + "font-size: 18px; font-weight: 600;"
      );
    },
    colorProgress() {
      //return "color:" + this.color.theme + ";";
      return "color:" + "white" + ";";
    },
     headerTable() {
      return (
        "color:" + this.color.theme + ";" + "font-weight: 600; font-size:15px;"
      );
    },
    headerPage() {
      // console.log(this.color.theme);

      return (
        "color:" + this.color.theme + ";" + "font-size: 18px; font-weight: 600;"
      );
    },
    colorVapp() {
      return "background:" + this.color.BG + ";";
    },
     resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    pageCount: {
      get() {
         // Old
        let l = this.rootfile.length;

        // New api
        // let l = this.fileCount;
        let s = this.size;
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      }
    },
  },
  methods:{
     clickRight(e, filename) {
      // console.log(e);
      e.preventDefault();
      this.sendParentfolder();
      this.x = e.clientX;
      this.y = e.clientY;
      this.showMenu = false;
      this.$nextTick(() => {
        // console.log(filename);
        this.currentfile = filename;
        this.showMenu = true;
      });
    },

       clickpagination(){
      this.offset = (this.page - 1) * this.size;
      console.log(this.offset);
      //console.log(this.page);
      //console.log(this.size);
      
      this.loadfileandfolder();
    },
      sendParentfolder() {
      // if(this.$route.params.id === undefined){
      //   this.parentfolder = ''
      // }else{
      //   this.parentfolder = this.$route.params.id
      // }

      this.parentfolder = this.account_active["directory_id"];
      // console.log(this.parentfolder);
    },
    async loadfileandfolder(){
      this.loaddataprogress = true
      let cal_limit = this.size;
      let cal_offset = this.size * (this.page++ - 1);
      // console.log(cal_offset)
      if(cal_offset == 0){
         this.rootfile = []
         this.buff = []
      }
      this.disablescrolling = true
      let payload = {
        user_id: this.dataUsername,
        account_id: this.dataAccountId,
        folder_id: this.dataAccountActive["business_info"][
              "compliance_folder"
            ],
        status_file: "",
        status_folder: "",
        limit:  cal_limit ,
        offset: cal_offset,
      };
      let auth = await gbfGenerate.generateToken();
     await this.axios.post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/select_files_and_folder",payload,{headers: { Authorization: auth.code },})
        .then(async (response) => {
            this.loaddataprogress = false;
          if (response.data.status === "OK") {
            this.permission_currentfolder = response.data.your_folder[0]["permission_account"];
            for (let i = 0; i < response.data.data.length; i++) {
              let dataFileAndFolder = {};
              let typefile = response.data.data[i].name.split(".");
              let type = response.data.data[i].data_type;

              dataFileAndFolder["account_reciever"] = "";
              dataFileAndFolder["account_sender"] = this.dataAccountId;
              dataFileAndFolder["account_id"] =response.data.data[i].account_id;
              dataFileAndFolder["countnum"] = response.data.data[i].countnum;
              dataFileAndFolder["file_createdtm"] =response.data.data[i].cre_dtm;
              dataFileAndFolder["folder_id"] = response.data.data[i].folder_id;
              dataFileAndFolder["file_id"] = response.data.data[i].id;
              dataFileAndFolder["file_lastdtm"] = response.data.data[i].last_dtm;
              dataFileAndFolder["file_linkshare"] = response.data.data[i].link;
              dataFileAndFolder["file_name"] = response.data.data[i].name;
              dataFileAndFolder["file_owner_eng"] = response.data.data[i].name_eng;
              dataFileAndFolder["file_owner_th"] = response.data.data[i].name_th;
              dataFileAndFolder["file_permission"] = response.data.data[i].permission_account;
              dataFileAndFolder["permission_department_setting"] = response.data.data[i].permission_setting;
              dataFileAndFolder["priority"] = response.data.data[i].priority;
              dataFileAndFolder["file_size"] = response.data.data[i].size;
              dataFileAndFolder["file_status"] = response.data.data[i].status;
              dataFileAndFolder["file_status_share"] = response.data.data[i].status_share;
              dataFileAndFolder["file_status_sharelink"] = response.data.data[i].status_share_link;
              dataFileAndFolder["system"] = response.data.data[i].system;
              dataFileAndFolder["type"] = response.data.data[i].data_type;
              dataFileAndFolder["file_icon"] = this.seticonNew(type, typefile[typefile.length - 1]);
              dataFileAndFolder["file_type"] = type == "folder" ? "folder" : typefile[typefile.length - 1];
              dataFileAndFolder["user_id"] = response.data.data[i].user_id;
              dataFileAndFolder["status_lock_folder"] = response.data.data[i]["status_lock_folder"]
              dataFileAndFolder["status_lock_file"] = response.data.data[i]["status_lock_file"]
              dataFileAndFolder["date_effect"] = response.data.data[i].header_info["date_effect"]
              dataFileAndFolder["doc_id"] = response.data.data[i].header_info["doc_id"]
              dataFileAndFolder["tax_version"] = "00"
              this.rootfile.push(dataFileAndFolder);
            }
            this.countdata = response.data.count;
            this.totaldata = {
              totalall: response.data.count, totalfile: response.data.count_file, totalfolder: response.data.count_folder
            }
            this.permission_currentfolder = response.data.your_folder[0]["permission_account"];
            this.aspectData=[{ aspect: response.data.your_folder[0]["aspect"],aspect_key: response.data.your_folder[0]["aspect_key"],}];
             this.disablescrolling = this.totaldata['totalall'] <= this.rootfile.length
             for(let y = 0; y < this.rootfile.length; y++){
              if(this.rootfile[y].file_owner_th === '' || this.rootfile[y].file_owner_eng === ''){
                await this.get_name_owner(this.rootfile[y])
              }
            }
          }else{
              this.disablescrolling = true
          }
        }) .catch((error) => {
          console.log(error);
          this.loaddataprogress = false;
          this.disablescrolling = true
          Toast.fire({ icon: "error",  title: this.$t("toast.cannotconnectonebox"),});});
    },
        //ดึงข้อมูลเจ้าของไฟล์ที่ไม่ใช่ของฉัน
        async get_name_owner(listrootfile){
      console.log("listrootfile",listrootfile);
      let payload = {
        data_id : listrootfile.file_id ,
        data_type : listrootfile.file_type === 'folder' ? 'folder' : 'file',
        type_search : "creator"
      }
      console.log("payload",payload);
      let auth = await gbfGenerate.generateToken();
      console.log("auth",auth);
      console.log("auth.code",auth.code);
      await this.axios
        .post(process.env.VUE_APP_SERVICE_SELECT_FILE + "/api/v1/get/data/creator_name", payload, {
          headers: { Authorization: auth.code },
        })
        .then((response) => {
          if (response.data.status === "OK") {
            listrootfile.file_owner_eng = response.data.name.name_eng
            listrootfile.file_owner_th = response.data.name.name_th
          } 
        })
        .catch((error) => {
          Toast.fire({
            icon: "error",
            title:"ไม่สามารถเรียกไฟล์ได้ในขณะนี้",
          });
        });
    },
    formatdatetime(_datetime) {
        console.log("dateeeeeee",_datetime);
      if (_datetime === "" || _datetime === "-" || _datetime === undefined || _datetime === null ) {
        return "-";
      } else {
        let dateyear =
          _datetime.split("")[0] +
          _datetime.split("")[1] +
          _datetime.split("")[2] +
          _datetime.split("")[3];
        let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
        let dateday = _datetime.split("")[6] + _datetime.split("")[7];
        let hour = _datetime.split("")[8] + _datetime.split("")[9];
        let minute = _datetime.split("")[10] + _datetime.split("")[11];
        let second = _datetime.split("")[12] + _datetime.split("")[13];

        return (
          dateday +
          "/" +
          datemonth +
          "/" +
          dateyear +
          " " +
          hour +
          ":" +
          minute +
          ":" +
          second
        );
      }
    },
    calculatesize(_sizefilebyte) {
      let size;
      if (_sizefilebyte >= 1099511992567 && _sizefilebyte <= 1125899906842624) {
        size = (_sizefilebyte / 1099511992567).toFixed(2) + " TB";
      } else if (_sizefilebyte >= 1073741824 && _sizefilebyte < 1099511992567) {
        size = (_sizefilebyte / 1073741824).toFixed(2) + " GB";
      } else if (_sizefilebyte >= 1048576 && _sizefilebyte < 1073741824) {
        size = (_sizefilebyte / 1048576).toFixed(2) + " MB";
      } else if (_sizefilebyte >= 1024 && _sizefilebyte < 1048576) {
        size = (_sizefilebyte / 1024).toFixed(2) + " KB";
      } else if (_sizefilebyte === "-") {
        size = _sizefilebyte;
      } else {
        size = _sizefilebyte + " B";
      }

      return size;
    },
   seticonNew(type, raw_parameter) {
      // console.log(parameter);
      
      let dataicon;
      let parameter
      if(typeof raw_parameter === 'string'){
        parameter = raw_parameter.toLowerCase()
      }else{
        parameter = raw_parameter
      }
      // let parameter_ = parameter !== "" || parameter !== undefined || parameter !== null ? parameter.toLowerCase() : parameter
      if (type == "folder") {
        dataicon = ["folder", "#FDD361"];
      } else {
        if (parameter === "xlsx" || parameter === "xls") {
          dataicon = ["mdi-file-excel-outline", "green"];
        } else if (parameter === "pptx" || parameter === "ppt") {
          dataicon = ["mdi-file-powerpoint-outline", "orange"];
        } else if (parameter === "docx" || parameter === "doc") {
          dataicon = ["mdi-file-word-outline", "primary"];
        } else if (parameter === "zip") {
          dataicon = ["mdi-zip-box-outline", "blue-grey"];
        } else if (parameter === "pdf") {
          dataicon = ["mdi-file-pdf-outline", "red"];
        } else if (parameter === "xml") {
          dataicon = ["mdi-file-code-outline", "orange"];
        } else if (parameter === "txt" || parameter === "txt") {
          dataicon = ["mdi-note-text-outline", "blue-grey"];
        } else if (
          parameter === "jpeg" ||
          parameter === "jpg" ||
          parameter === "png"
        ) {
          dataicon = ["mdi-file-image-outline", "blue-grey"];
        } else if(parameter === "shortcut"){
      dataicon=["mdi-file-link-outline","pink"]
    }
        else {
          // dataicon = ["mdi-file-question-outline", "black"];
          dataicon = ["mdi-file-outline", "black"];
        }
      }

      return dataicon;
    },
    zeroPadding(num, digit) {
      var zero = "";
      for (var i = 0; i < digit; i++) {
        zero += "0";
      }
      return (zero + num).slice(-digit);
    },  
  async  multipledownload() {
      console.log("CRC", this.filemultipledownload);
      this.filemultipledownload.push(this.currentfile)
      this.percentCompleted = 0;
      var d = new Date();
      this.datetime =
        d.toISOString().slice(0, 10) +
        " " +
        "at" +
        " " +
        this.zeroPadding(d.getHours(), 2) +
        "-" +
        this.zeroPadding(d.getMinutes(), 2) +
        "-" +
        this.zeroPadding(d.getSeconds(), 2);
      if (this.filemultipledownload.length < 1) {
        Toast.fire({
          icon: "warning",
          title: this.$t("myinboxPage.msg.nofile")
        });
      } else {
        let filedata = [];
        for (let i = 0; i < this.filemultipledownload.length; i++) {
          // push type
          if (this.filemultipledownload[i]["file_type"] === "folder") {
            filedata.push({
              id: this.filemultipledownload[i]["file_id"],
              type: this.filemultipledownload[i]["file_type"]
            });
          } else {
            filedata.push({
              id: this.filemultipledownload[i]["file_id"],
              type: "file"
            });
          }
        }
        let payload = {
          list: filedata,
          account_id: this.dataAccountId
        };
        console.log("payloads", payload);
        // this.snackbardowloadfile = true;
        console.log("ถึงง");
        this.opensnackbarmultidownload = true;
        let auth = await gbfGenerate.generateToken();
        this.axios
          .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_MULTIPLE +
              "/api/download_multiples_all",
            payload,
            {
              onDownloadProgress: progressEvent => {
                // console.log(progressEvent);
                let progresspercent = parseInt(
                  Math.round((progressEvent.loaded / progressEvent.total) * 100)
                );
                // console.log(progresspercent);
                if (progresspercent >= 95) {
                  this.percentCompleted = 100;
                } else {
                  this.percentCompleted = progresspercent;
                }
                this.percent = this.percentCompleted
                console.log("this.percent",this.percent);
              },
              withCredentials: false,
              responseType: "arraybuffer",
              headers: { Authorization: auth.code },
            }
          )
          .then(response => {
            console.log(response);
            if (response.statusText === "OK") {
              this.percentCompleted = 100;
            }
            const blob = new Blob([response.data]);
            const content = response.headers["content-type"];
            saveAs(blob, this.datetime + ".zip");
            this.clearmultipledownload();
          })
          .catch(error => {
            this.$store.dispatch("show_loading", false);
            Toast.fire({
              icon: "error",
              // title: this.$t("myinboxPage.msg.apierror")
              title: response.data.errorMessage
            });
            console.log(error);
          });
      }
    },
     async downloadfile(currentfile) {
      // console.log("dnnnnn");
      let datadowload = {};

      // console.log(this.listdatadowload);
      let checkdata = this.listdatadowload.findIndex(
        v => v.file_id === currentfile.file_id
      );
      if (checkdata != -1) {
        Toast.fire({
          icon: "error",
          title: "พบการดาวน์โหลดซ้ำ กรุณาดาวน์โหลดใหม่อีกครั้ง"
        });
        this.snackbardowload = false;
        this.listdatadowload = [];
        this.newlist = [];
      } else {
        this.listdatadowload.push(currentfile);

        console.log(this.listdatadowload);

        for (let i = 0; i < this.listdatadowload.length; i++) {
          datadowload["fileid"] = this.listdatadowload[i].file_id;
          datadowload["name"] = this.listdatadowload[i].file_name;
          datadowload["value"] = 0;
          console.log(datadowload);
        }

        this.newlist.push(datadowload);
        console.log(this.newlist);

        // for(let i = 0; i < this.newlist.length;i++){
          //  let checkdata = this.newlist.findIndex(v => v.fileid === this.newlist.fileid);
        //   if(checkdata == -1) {
          
          //     console.log("ไม่เจอตัวซ้ำ",checkdata);
        //   }else{
          //     console.log('เจอออซ้ำ');

        //   }

        // }

        this.percentCompleted = 0;
        if (currentfile.file_type === "folder") {
          let url =
            process.env.VUE_APP_SERVICE_DOWNLOAD_FOLDER +
            "/api/v2/download_folder?account_id=" +
            this.dataAccountId +
            "&folder_id=" +
            currentfile.file_id;

          for (let i = 0; i < this.listdatadowload.length; i++) {
            this.dataIndex = this.newlist.findIndex(
              obj => obj.fileid === this.listdatadowload[i].file_id
            );
          }
          console.log("folder", this.dataIndex);
          
          this.opensnackbar = true;
          this.new_list = this.newlist

          await this.axios
            .get(url, {
              onDownloadProgress: progressEvent => {
                let progresspercent = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );

                if (this.newlist[this.dataIndex]["value"] === 95) {
                  this.newlist[this.dataIndex]["value"] = 95;
                } else {
                  this.newlist[this.dataIndex]["value"] = progresspercent;
                }
                console.log("percenfolder",this.newlist[this.dataIndex]["value"]);
                this.newpercen = this.newlist[this.dataIndex]["value"]
                this.namefile = this.newlist[this.dataIndex]["name"]

              },
              withCredentials: false,
              responseType: "arraybuffer"
            })
            .then(response => {
              console.log(response);
              if (response.statusText === "OK") {
                this.newlist[this.dataIndex]["value"] = 100;
              }
              const blob = new Blob([response.data]);
              const content = response.headers["content-type"];
              saveAs(blob, currentfile.file_name + ".zip");
              // this.opensnackbar = false;
            })
            .catch(error => {
              Toast.fire({
                icon: "error",
                title: "ไม่สามารถดาวน์โหลด " + currentfile.file_name + " นี้ได้"
              });
              console.log(error);
            });
        } else {
          let payload = {
            account_id: this.dataAccountId,
            user_id: this.dataUsername,
            file_id: currentfile.file_id
          };
          let url =
            process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
            "/api/v2/download_file?user_id=" +
            this.dataUsername +
            "&file_id=" +
            currentfile.file_id;

          for (let i = 0; i < this.listdatadowload.length; i++) {
            this.dataIndex = this.newlist.findIndex(
              obj => obj.name === this.listdatadowload[i].file_name
            );
          }
          console.log("file", this.dataIndex);

          this.opensnackbar = true;
          this.new_list = this.newlist
          await this.axios
            .get(url, {
              onDownloadProgress: progressEvent => {
                let progresspercent = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                console.log(this.percentCompleted);

                if (this.newlist[this.dataIndex]["value"] === 95) {
                  this.newlist[this.dataIndex]["value"] = 95;
                } else {
                  this.newlist[this.dataIndex]["value"] = progresspercent;
                }
                 console.log("percenfile",this.newlist[this.dataIndex]["value"]);
                 this.newpercen = this.newlist[this.dataIndex]["value"]
                 this.namefile = this.newlist[this.dataIndex]["name"]
              },
              withCredentials: false,
              responseType: "arraybuffer"
            })
            .then(response => {
              if (response.statusText === "OK") {
                this.newlist[this.dataIndex]["value"] = 100;
              }
              const blob = new Blob([response.data]);
              const content = response.headers["content-type"];
              saveAs(blob, currentfile.file_name);
              // this.opensnackbar = false;
            })
            .catch(error => {
              Toast.fire({
                icon: "error",
                title: "ไม่สามารถดาวน์โหลด " + currentfile.file_name + " นี้ได้"
              });
              console.log(error);
            });
        }
      }
    },
    // async download(currentfile) {
    //   let response = await this.axios.get(
    //     process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
    //       "/api/v2/download_file?user_id=" +
    //       this.dataUsername +
    //       "&file_id=" +
    //       currentfile.file_id,
    //     { responseType: "arraybuffer" }
    //   );
    //   let blob = new Blob([response.data], {
    //     type: response.headers.get("content-type")
    //   });
    //   let link = document.createElement("a");
    //   link.href = window.URL.createObjectURL(blob);
    //   link.download = currentfile.file_name;
    //   link.click();
    // },
  }, mounted() {
      this.loadfileandfolder();
      this.disablescrolling = false
      this.loaddataprogress = false;
  }
}
</script>
<style>
.v-snack__content {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
}
#thiscontainerlibrary {
 height: calc(101vh - 180px);
  /* height: calc(var(--vh, 1vh) * 100); */
  overflow-x: hidden;
  /* max-width: 100%; */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
}
</style>